import React from "react"

// Components
import CustomButton from "../CustomButton"

const AvailableButtons = () => {

    return (
        <div className="button-wrapper">
            <CustomButton
                variant="green"
                onClick={ () => {
                    console.log( "Button Clicked" );
                } }
                value="AVAILABLE LETTINGS SUMMARY SHEET"
            />
            <CustomButton
                variant="outline"
                onClick={ () => {
                    console.log( "Button Clicked" );
                } }
                value="FULL AVAILABLE LETTINGS LIST"
            />
        </div>
    )
}

export default AvailableButtons