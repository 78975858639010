import React, { useState, useEffect } from "react"
import { Table } from "react-bootstrap"
import dateFormat from "dateformat";
import {Link} from "gatsby";

import CustomButton from "../CustomButton"
import {GetAPIData, Property_API} from "../common/agentAPI"
import {ImageModule} from '../../modules/Image_Module'

const pageSize = 15;

const DashboardDetails = ({status, buildingType, bedrooms, pageNo, setPageNo}) => {

   const[tableList, setTableList] = useState([]);
   const[loading, setLoading] = useState(true);
   
    useEffect(() => {

        var dasboard_api = Property_API+`?pageSize=${pageSize}&page=${pageNo}`;
        if(status){
            dasboard_api += `&status=${status}`
        }
        if(buildingType){
            if(buildingType !== "All"){
                dasboard_api += `&parent_building=${buildingType}`
            }        
        }
        if(bedrooms && bedrooms !== "All"){
            if(bedrooms === "4+"){
                dasboard_api += `&bedroom_gt=${bedrooms.replace('+','')}`
            } else{
                dasboard_api += `&bedroom=${bedrooms}`
            }
            
        }

        GetAPIData(dasboard_api).then(async apiRes => {
            if(apiRes?.success === true){
                setTableList(apiRes)
                setLoading(false)
            }
        });
        
    }, [bedrooms, buildingType, status, pageNo])

    return (
    <>
        <div className="filter-table">
            <Table responsive hover>
                <thead>
                    <tr>
                        <th>Property</th>
                        <th>Building</th>
                        <th className="text-center">Bedrooms</th>
                        <th className="text-center">Available From</th>
                        <th className="text-center">Assets</th>
                    </tr>
                </thead>
                {!loading &&
                <tbody>
                    {tableList?.results?.length > 0 ?
                        tableList.results?.map(item => <TableRow item={item}/>)
                        
                    :
                        <tr>
                            <td colSpan="5" align="center">No records found.</td>
                        </tr>
                    }
                </tbody>
                }
            </Table>
        </div>
        
        {tableList?.pagination?.pageCount >  pageNo &&
            <div>
                <CustomButton
                    variant="outline"
                    onClick={ () => setPageNo(pageNo+1)}
                    value="LOAD MORE"
                />
            </div>
        }
        
    </>
    )
}

export default DashboardDetails

const TableRow = ({item}) => {

    const [ hovered, setHovered ] = useState( false );
    let downloadIcon = hovered ? 'download-icon-white.png' : 'download-icon.png';
    
    var item_bedrooms = item?.bedroom === 0 ? "Studio" : item?.bedroom;

    var building_link = `/property-building/${item?.parent_building?.slug}-${item?.parent_building?._id}`;

    return (
        <tr
            onMouseEnter={ () => setHovered( true ) }
            onMouseLeave={ () => setHovered( false ) }
        >
            <td><PropertyShortInfo  item={item}/></td>
             <td className="building"><Link to={building_link}>{item.parent_building && item.parent_building.building_name}</Link></td>
            <td className="text-center bedrooms">{item_bedrooms}</td>
            <td className="text-center available-from">{item.available_from && dateFormat(item.available_from, "dS mmmm") }</td>
            <td className="text-center assets">
                <a
                    href="#"
                    className="no-decoration"
                >
                    <img
                        src={ require( `../../images/${ downloadIcon }` ).default }
                        alt="Download Icon"
                    />
                </a>
            </td>
        </tr>
    )
}

const PropertyShortInfo = ({item}) => {

    var property_link = "/residential-property-for-sale";

    if(item.department === "residential"){
        if(item.search_type === "sales"){
            property_link = "/residential-property-for-sale";
        } else{
            property_link = "/residential-property-to-rent";
        }
    } 

    if(item.department === "commercial"){
        if(item.search_type === "sales"){
            property_link = "/commercial-property-for-sale";
        } else{
            property_link = "/commercial-property-to-rent";
        }
    } 

    property_link += `/${item?.slug}-${item?._id}`;

    return (
        <div className="property-short-info-wrapper">
            <span className="property-image">
                <ImageModule ImageSrc={item?.images?.length > 0 && item?.images[0]} altText={item.display_address} />
            </span>
            <div className="property-details">
                <p className="address"><Link to={property_link}>{item?.display_address ? item.display_address : item?.building_name}</Link></p>
                {item?.price && <p className="price">£{item.price.toLocaleString()} p/w</p>}
            </div>
        </div>
    )
}
