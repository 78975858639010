import React, {useState, useEffect} from "react"
import { Link, navigate } from "@reach/router";

// Components
import Layout from "../components/layout"
import BreadCrumbs from "components/BreadCrumbs/StaticBreadcrumb"
import DashboardDetails from "layout/Dashboard/DashboardDetails"
import {isLoggedIn, getUser} from "../services/auth"
import {CustomLinks} from "../components/common/menuUtils"
import {SEODetails} from "../components/common/static_seo"

const breadCrumbsData = [
    {
        name: 'Home',
        link: '/'
    },
    {
        name: 'Register as an Agent',
    },
];


const Dashboard = () => {
    const [isAuth, setIsAuth] = useState(isLoggedIn());
    const [get_logged_user, setLoggedUser] = useState(getUser());

    useEffect(() => {
        if(isAuth){
            navigate('/'+CustomLinks.dashboard+'/')
        } else{
            navigate('/'+CustomLinks.login+'/')
        }
    },[isAuth]);


    return (
        <Layout variant={""} Meta_Title={SEODetails.dashboard.title} Meta_Description={SEODetails.dashboard.description}>
            <BreadCrumbs
                breadCrumbsData={ breadCrumbsData }
            />
            {get_logged_user?.suname && 
                <DashboardDetails user_data={get_logged_user}/>
            }
        </Layout>
    )
}

export default Dashboard
